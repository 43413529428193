<script setup lang="ts">
import LogoSite from '/src/assets/store_logo_min.png'
import { useWindowScroll } from '@vueuse/core'
import CartBtnComponent from '@/components/games/CartBtnComponent.vue'

const { windowWidth, isDesktop, screens } = tools
const { gameOrder, list } = gamesUtils

const isExpand = ref(false)
const { y: windowScrollY } = useWindowScroll()
const scrollObserver = ref({
  y: 0,
  yPrev: 0,
  isHiddenHeader: false
})
const route = useRoute()
function initWatchScrollY() {
  if (windowWidth.value < 900) {
    return watch(
      () => windowScrollY.value,
      (scrollY) => {
        scrollObserver.value.isHiddenHeader = !(scrollY <= 250 || scrollObserver.value.yPrev > scrollY)
        scrollObserver.value.yPrev = scrollY
      }
    )
  }
  return null
}
let unWatchScrollY = initWatchScrollY()
watch(
  () => windowWidth.value,
  () => {
    if (windowWidth.value >= 900 && unWatchScrollY !== null) {
      unWatchScrollY()
      unWatchScrollY = null
    } else if (unWatchScrollY === null) {
      unWatchScrollY = initWatchScrollY()
    }
  }
)

interface MenuItem {
  title: string
  route: Games.GamesRoute
  gameId?: string
}

const gamesMenu: Array<MenuItem> = gameOrder
  .filter((gameKey) => {
    return list[gameKey].active
  })
  .map((gameKey) => {
    return { title: list[gameKey].name, route: list[gameKey].route, gameId: list[gameKey].id }
  })
const sliceSize = computed(() => {
  let slice = 4
  if (screens.value.big.alLeast && !screens.value.lg.alLeast) {
    slice = 3
  }
  return Math.min(gamesMenu.length, slice)
})
const infoPages: Array<MenuItem> = [
  { title: 'About', route: { path: '/about', name: 'about-page' } },
  { title: 'Help', route: { path: '/help', name: 'help-page' } }
]

const menuItems = computed(() => {
  const items = gamesMenu.slice(0, sliceSize.value)
  if (items.length <= 2) {
    items.push(...infoPages)
  }
  return items
})

const otherItems = computed<Array<MenuItem>>(() => {
  const items: MenuItem[] = []
  if (menuItems.value.length > 2) {
    items.push(...gamesMenu.slice(sliceSize.value))
    items.push(...infoPages)
  }
  return items
})

function onClickMenuLink() {
  isExpand.value = false
}

const isOtherPageActive = computed(() => {
  return otherItems.value.some(item => route.path === item.route?.path)
})

watch(
  () => `${isDesktop.value}_${isExpand.value}`,
  () => {
    if (!isDesktop.value && isExpand.value) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }
)
</script>

<template>
  <div
    v-if="isExpand && !isDesktop"
    class="bg-white opacity-90 fixed inset-0 z-50"
    @click="isExpand = !isExpand"
  />
  <header
    class="fixed top-0 z-50 max-h-[90vh] w-full overflow-auto bg-white py-3 big:overflow-visible"
    :class="{ 'is-hidden': !isDesktop && scrollObserver.isHiddenHeader }"
  >
    <div
      class="big:min-h-[63px] mx-auto flex flex-col big:flex-row h-full max-w-screen-xl justify-between big:justify-start px-0 big:px-2 lg:px-8 items-center leading-none"
    >
      <div class="w-full big:w-auto flex justify-between pl-3 big:pl-0 leading-none">
        <router-link
          to="/"
          @click="isExpand = false"
        >
          <img
            :src="LogoSite"
            class="mx-4 h-9 big:h-12 big:py-1"
            alt="store image"
          >
        </router-link>
        <div class="flex items-center justify-center big:hidden">
          <CartBtnComponent icon-class="text-xl ml-2" />
          <button-hamburger v-model="isExpand" />
        </div>
      </div>
      <nav class="w-full big:w-auto flex flex-col items-end big:flex-row big:items-center justify-between flex-grow">
        <transition name="accordion-transition">
          <div
            v-if="isExpand || isDesktop"
            class="grid transition-all duration-300 ease-in w-full whitespace-nowrap min-w-fit"
          >
            <div class="overflow-hidden">
              <div class="overflow-hidden w-full big:w-auto">
                <ul
                  class="flex flex-col big:!max-h-full big:flex-row menu"
                  :class="{ 'border-t-2 border-slate-100 big:border-none mt-3 w-full': isExpand && !isDesktop }"
                >
                  <li
                    v-for="(item, itemIndex) in menuItems"
                    :key="itemIndex"
                    class="cursor-pointer px-0 py-[3px] text-center big:px-2 big:py-0 big:text-left"
                    @click="onClickMenuLink"
                  >
                    <router-link
                      :to="item.route.path"
                      :title="item.title"
                      class="block cursor-pointer py-3 big:py-1 w-full border-b big:border-0 uppercase big:normal-case"
                      :class="{ 'router-link-active': item.gameId && item.gameId === route.params?.gameID }"
                    >
                      {{ item.title }}
                    </router-link>
                  </li>
                  <li
                    v-if="isDesktop && otherItems.length"
                    class="cursor-pointer px-0 py-[3px] text-center big:px-2 big:py-0 big:text-left"
                  >
                    <el-popover
                      class="inherit-font-size inherit-line-height"
                      :show-arrow="false"
                      popper-class="p-0 rounded-xl text-[18px]"
                      placement="bottom-start"
                    >
                      <template #reference>
                        <a
                          :class="{ 'router-link-active': isOtherPageActive }"
                          class="block cursor-pointer py-3 big:py-1 w-full border-b big:border-0 uppercase big:normal-case"
                        >
                          Other
                          <icon-ify
                            icon="mingcute:down-fill"
                            class="inline"
                          />
                        </a>
                      </template>
                      <ul>
                        <li
                          v-for="(item, itemIndex) in otherItems"
                          :key="itemIndex"
                          class="cursor-pointer px-3 py-[3px] text-left border-b font-bold"
                          :class="{ 'router-link-active': item.gameId && item.gameId === route.params?.gameID }"
                          @click="onClickMenuLink"
                        >
                          <router-link
                            :to="item.route.path"
                            :title="item.title"
                            class="block cursor-pointer py-3 big:py-1 w-full border-b big:border-0 uppercase big:normal-case"
                          >
                            {{ item.title }}
                          </router-link>
                        </li>
                      </ul>
                    </el-popover>
                  </li>
                  <template v-else-if="!isDesktop">
                    <li
                      v-for="(item, itemIndex) in otherItems"
                      :key="itemIndex"
                      class="cursor-pointer px-0 py-[3px] text-center big:px-2 big:py-0 big:text-left"
                      @click="onClickMenuLink"
                    >
                      <router-link
                        :to="item.route.path"
                        :title="item.title"
                        class="block cursor-pointer py-3 big:py-1 w-full border-b big:border-0 uppercase big:normal-case"
                        :class="{ 'router-link-active': item.gameId && item.gameId === route.params?.gameID }"
                      >
                        {{ item.title }}
                      </router-link>
                    </li>
                    <li class="cursor-pointer px-0 py-[3px] text-center big:px-2 big:py-0 big:text-left">
                      <div class="my-3">
                        <log-in-btn @click="isExpand = false" />
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </transition>
        <template v-if="isDesktop">
          <CartBtnComponent icon-class="text-2xl ml-2 mr-4" />
          <div class="ml-auto mr-0">
            <log-in-btn
              style="--el-button-size: 48px"
              @click="isExpand = false"
            />
          </div>
        </template>
      </nav>
    </div>
  </header>
</template>

<style scoped lang="postcss">
header {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  transition: top 0.5s ease-in-out;
  &.is-hidden {
    top: -100vh !important;
  }
  ul {
    transition: max-height 0.2s ease-out;

    li {
      letter-spacing: 0.1px;
      a {
        font-weight: 700;
      }
    }
  }
}
</style>

<style lang="postcss">
body {
  padding-top: 60px;
  margin-top: 0;
  &.expand-menu {
    overflow: hidden;
  }
}
@media (min-width: 900px) {
  body {
    padding-top: 81px !important;
    margin-top: 0;
  }
}
</style>
