<script setup lang="ts">
import UserIcon from '@/assets/user.png?url'
import LogoHands from '/src/assets/logo_hands.svg'
import LoyaltyBoxesInfoComponent from '@/components/shared/LoyaltyBoxesInfoComponent.vue'
import { visibilitySelectorGame } from '@/components/layout/SelectorGame/SelectorGameCompose'

const { buyOffer, logOut, showLogInDialog, isLogged, routeGame, gameLoyalty, triggerApi } = gamesUtils
const { isDesktop } = tools

function logOutUser() {
  logOut(routeGame.value?.gameKey)
  buyOffer.value = null
}

const withSelectGame = computed(() => {
  return !routeGame.value?.id
})
const isFakeLoading = ref(false)
function onRefresh() {
  if (isFakeLoading.value) {
    return
  }
  isFakeLoading.value = true
  triggerApi.callTrigger()
  setTimeout(() => {
    isFakeLoading.value = false
  }, 1000)
}
</script>

<template>
  <template v-if="withSelectGame">
    <el-button
      color="#3ba6bc"
      class="text-white rounded-lg uppercase relative ease-in-out duration-700"
      size="large"
      @click="visibilitySelectorGame = true"
    >
      <div class="text-base tracking-wide pr-8">
        log in
        <div class="absolute top-0 right-2 bottom-0 w-10 p-1 flex items-center">
          <LogoHands class="max-h-full w-full h-full" />
        </div>
      </div>
    </el-button>
  </template>
  <transition
    v-else
    name="el-fade-in-linear"
    mode="out-in"
  >
    <template v-if="!isLogged">
      <el-button
        color="#3ba6bc"
        class="text-white rounded-lg uppercase relative ease-in-out duration-700"
        size="large"
        @click="showLogInDialog(true)"
      >
        <div class="text-base tracking-wide pr-8">
          log in
          <div
            v-if="routeGame?.icon"
            class="absolute top-0 right-1 bottom-0 w-10 p-1 flex items-center"
          >
            <el-image
              :src="routeGame.icon"
              class="max-h-full rounded-2xl shadow-md shadow-gs-bg"
            />
          </div>
        </div>
      </el-button>
    </template>
    <div
      v-else
      class="mx-auto ease-in-out duration-700 max-w-xs"
    >
      <div class="rounded-2xl pl-1.5 pr-3 big:px-3 py-2 bg-black text-white flex justify-between items-center">
        <div>
          <el-popover
            v-if="isDesktop"
            placement="bottom"
            effect="dark"
            :show-after="300"
            :offset="25"
            width="auto"
            popper-class="rounded-2xl"
          >
            <template #reference>
              <el-image
                :src="UserIcon"
                class="w-[35px] cursor-pointer"
              />
            </template>
            <template #default>
              <energy-and-gems-component />
              <div class="text-right text-white text-sm mb-2">
                <button
                  class="translate-y-1 hover:big:text-cyan-500 active:big:text-cyan-400"
                  @click="onRefresh"
                >
                  <span v-show="isFakeLoading">
                    <icon-ify
                      icon="pepicons-pop:refresh"
                      class="w-5 inline"
                      :class="{ 'animate-spin': isFakeLoading }"
                    />
                  </span>
                  - refresh -
                </button>
              </div>
              <div class="px-2 text-center flex justify-center">
                <el-button
                  color="#3ba6bc"
                  class="text-white rounded-lg relative px-2 big:px-4"
                  size="default"
                  @click="logOutUser()"
                >
                  <div class="text-base tracking-wide uppercase">
                    log out
                  </div>
                </el-button>
              </div>
            </template>
          </el-popover>
          <el-image
            v-else
            :src="UserIcon"
            class="w-[35px] cursor-pointer"
          />
        </div>
        <div class="max-w-[50%] big:max-w-max">
          <div
            class="grow pl-2 text-base big:font-semibold leading-none text-left truncate max-w-[200px] big:max-w-[155px] xl:max-w-[220px]"
          >
            {{ $store?.getters?.['game/token'](routeGame?.gameKey)?.name }}
          </div>
          <template v-if="isDesktop && gameLoyalty?.id">
            <LoyaltyBoxesInfoComponent
              :offset="18"
              popper-class="bg-gs-body-bg rounded-lg"
              wrapper-class="flex justify-end pl-4 mt-0.5 items-center text-xs text-black"
              box-class="rounded bg-gs-body-bg pl-4 py-1 h-6 max-w-[200px] flex justify-start items-center shrink-0"
              box-img-class="w-6"
              box-icon-class="right-1 top-1/2 -translate-y-1/2 text-sm"
            />
          </template>
        </div>
        <div
          v-if="!isDesktop"
          class="px-2 text-center flex justify-center"
        >
          <el-button
            color="#3ba6bc"
            class="text-white rounded-lg relative px-2 big:px-4"
            size="default"
            @click="logOutUser()"
          >
            <div class="text-base tracking-wide uppercase">
              log out
            </div>
          </el-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="postcss">
.el-button:hover {
  .el-image {
    opacity: 50%;
  }
}
</style>
