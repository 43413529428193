<script setup lang="ts">
import { cartDrawerGameKey, cartDrawerVisible } from './CartDrawerCompose'
import { utilsPlugin } from '@/plugins/utilsPlugin'

const { windowWidth } = tools
const { routeGame, loading, showCheckout, list } = gamesUtils
const route = useRoute()

const gameKey = computed(() => {
  return routeGame.value?.gameKey || cartDrawerGameKey.value || ''
})

const gameSettings = computed(() => {
  return list[gameKey.value ?? ''] ?? {}
})

const gameCart = computed(() => {
  const _gameCart: Games.Offer[] = utilsPlugin.getAppGlobalProperties()?.$store?.getters?.['game/gameCart']?.(gameKey.value)
  return _gameCart
})

function setQty(value: number | undefined, index: number, offerId: number | string | undefined) {
  // https://vuex.vuejs.org/guide/forms.html
  utilsPlugin
    .getAppGlobalProperties()
    ?.$store?.commit('game/updateGameCartOffer', [gameKey.value, { qty: value, id: offerId }, index])
}
const drawerWidth = computed(() => {
  return Math.min(windowWidth.value * 0.9, 500)
})

if (!import.meta.env.SSR && route.query?.cart) {
  utilsPlugin.getAppGlobalProperties()?.$store.commit('game/clearGameCart', [route.query?.cart || ''])
  utilsPlugin.getAppGlobalProperties()?.$router?.replace(route.path)
}
function onClose() {
  loading.value = ''
}
const isSsr = import.meta.env.SSR

function removeFromCart(index: number) {
  if (utilsPlugin.siteGtag() && gameCart.value?.[index]) {
    const cartItem = gameCart.value[index]
    utilsPlugin.siteGtag()?.event('remove_from_cart', {
      game: gameKey.value,
      item: {
        item_id: cartItem.id,
        item_name: cartItem?.product || cartItem?.name,
        index,
        quantity: cartItem?.qty ?? 1,
        price: cartItem?.discount?.value || cartItem?.price?.value
      }
    })
  }
  utilsPlugin.getAppGlobalProperties()?.$store.commit('game/removeFromGameCart', [gameKey.value, index])
}
</script>

<template>
  <el-drawer
    v-if="!isSsr"
    v-model="cartDrawerVisible"
    :size="`${drawerWidth}px`"
    modal-class="cart-drawer"
    @closed="onClose"
  >
    <template #header>
      <div class="w-full flex justify-start items-center gap-x-2">
        <div>
          <icon-ify
            icon="tdesign:cart"
            class="inline text-2xl"
          />
        </div>
        <div v-if="gameSettings?.logo">
          <img
            :src="gameSettings.logo"
            alt="logo"
            class="w-28 max-h-12"
          >
        </div>
      </div>
    </template>
    <template #default>
      <div class="flex flex-col gap-y-4">
        <div
          v-for="(offer, index) in gameCart"
          :key="offer.id"
        >
          <div class="flex flex-row gap-1 items-center">
            <div class="w-3/12 pr-2">
              <img
                v-if="offer.asset"
                :src="offer.asset"
                loading="lazy"
                alt="offer img"
              >
            </div>
            <div class="w-9/12 relative pr-4">
              <div class="font-bold truncate">
                {{ offer.name || `Offer_${offer.id}` }}
              </div>
              <div class="flex flex-row flex-wrap gap-2 big:gap-x-3 text-sm text-neutral-600">
                <div>{{ offer?.discount?.format || offer?.price?.format }}</div>
                <div class="whitespace-nowrap">
                  x
                  <span v-if="!offer.allowQty || (offer.purchaseLimit || 1) === 1"> 1 </span>
                  <el-input-number
                    v-else
                    :model-value="offer.qty || 1"
                    :step="1"
                    step-strictly
                    :min="1"
                    :max="offer.purchaseLimit || 1"
                    size="small"
                    class="w-24 ml-1"
                    @update:model-value="setQty($event, index, offer?.id)"
                  />
                </div>
              </div>
              <el-popconfirm
                v-if="cartDrawerVisible"
                title="Are you sure?"
                class="test"
                @confirm="removeFromCart(index)"
              >
                <template #reference>
                  <icon-ify
                    icon="ph:trash"
                    class="absolute bottom-1 -right-1 hover:text-red-500 cursor-pointer outline-0"
                  />
                </template>
              </el-popconfirm>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="mb-1">
        <el-button
          :disabled="!gameCart?.length || !$store?.getters['game/gameCartPaymentAvailable'](gameKey)"
          class="text-white bg-neutral-300 [&:not(.is-disabled)]:bg-yellow-500 [&:not(.is-disabled)]hover:bg-yellow-400 rounded-lg text-lg px-6 py-2 h-auto tracking-wide uppercase font-semibold w-full min-h-12"
          size="large"
          :loading="loading === 'cart'"
          @click="showCheckout('cart', true)"
        >
          <div class="flex justify-center items-center flex-wrap gap-x-2 gap-y-0 leading-tight">
            <div>Payment</div>
            <div>{{ $store?.getters['game/gameCartSumValHuman'](gameKey) }}</div>
          </div>
        </el-button>
        <div
          v-if="!$store?.getters['game/gameCartPaymentAvailable'](gameKey)"
          class="text-left text-xs big:-mb-2"
        >
          The minimum cart value is: <b>{{ $store.getters['game/loyalty'](gameKey)?.minPurchaseCurHuman }}</b>.
        </div>
      </div>
    </template>
  </el-drawer>
</template>

<style lang="postcss">
.cart-drawer {
  .el-drawer {
    &__header {
      margin-bottom: 0;
    }

    &__footer {
      box-shadow: 0 -4px 3px rgb(246 239 239 / 75%);
    }
  }
}
</style>
