<script setup lang="ts">
import { loadingPage } from '@/components/layout/PageLoader/PageLoaderCompose'
import Logo from '@/assets/logo-green.svg'

const show = ref(import.meta.env.SSR)

watchEffect(() => {
  if (import.meta.env.SSR) {
    return false
  }
  if (loadingPage.value) {
    setTimeout(() => {
      if (loadingPage.value) {
        show.value = true
      }
    }, 200)
  } else {
    show.value = false
  }
})
</script>

<template>
  <transition name="opacity-bg-anim">
    <div
      v-if="show"
      class="fixed inset-0 z-50 bg-white flex justify-center items-center"
    >
      <div><Logo class="loader shadow-xl" /></div>
    </div>
  </transition>
</template>

<style scoped lang="postcss">
.loader {
  width: clamp(8rem, 7vw, 11rem);
  animation: rotateY 3s linear infinite;
  animation-delay: 200ms;
}

@keyframes rotateY {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
.opacity-bg-anim-enter-active {
  animation: opacity-bg 2s;
}
.opacity-bg-anim-leave-active {
  animation: opacity-bg 0.5s reverse;
}
@keyframes opacity-bg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
